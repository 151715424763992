
.App {
  width: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.App-menu {
  display: flex;
  justify-content: flex-start;
}

.board {
  display: flex;
  flex-direction: column;
}

.board_numbers {
  display: flex;
  flex-wrap: wrap;
}

.menu-container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 2rem;
  height: .25rem;
  background-color: #333;
  margin: .375rem 0;
  transition: 0.4s;
}

/* Rotate first bar */
.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-.56rem, .375rem) ;
  transform: rotate(-45deg) translate(-.56rem, .375rem) ;
}

/* Fade out the second bar */
.change .bar2 {
  opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-.5rem, -.5rem) ;
  transform: rotate(45deg) translate(-.5rem, -.5rem) ;
}

.App-content {
  display: flex;
  flex-direction: row;
}

.App-header {
  min-height: 10vh;
  align-items: center;
  justify-content: center;
}

.App-config {
  display: flex;
  border: .25rem solid Black;
  margin: .25rem;
}

.App-scores {
  position: absolute;
  left: 0; right: 0;
  overflow: auto;
  height: calc(100vh - 22rem);
}

.App-help {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: .25rem solid Black;
  padding: .25rem;
}

.reroll_button {
  font-size: 1.5rem;
  border-radius: 1rem;
  background-color: Blue;
  color: White;
  display: block-inline;
}

button:disabled {
  visibility: hidden;
}

/* Dropdown Button */
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 10rem;
  box-shadow: 0rem .5rem 1rem 0rem rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content span {
  color: black;
  padding: .75rem 1rem;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {background-color: #ddd}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.open {display:block;}
